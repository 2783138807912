<template>
  <CSidebar
    :style="{'background': $store.state.colorSideBar.hex8}"
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >

    <CSidebarBrand class="d-md-down-none" to="/">
      <!--<img src="/img/logoResp.png" class="c-sidebar-brand-full"/>--><!--:height="40"-->
      <img src="/img/alt60px_logo-gesto.png" :height="54" class="c-sidebar-brand-full"/>
      <img src="/img/gesto/logo.png" class="c-sidebar-brand-minimized" size="custom-size" :height="30" />
    </CSidebarBrand>


    <!--<CSidebarNav>
        <CSidebarNavItem>
            <CSidebarNavDropdown name="asdasd" :items="[{name: 'aSd', to: '/base/navbars'}]">
               <CSidebarNavItem name="asd123">
               </CSidebarNavItem>
            </CSidebarNavDropdown>
        </CSidebarNavItem>
        <CSidebarNavItem>
            <CSidebarNavDropdown name="asdasd"  :items="[{name: 'asd2', to: '/base/navbars', icon: 'cil-speedometer'}]">
            </CSidebarNavDropdown>
        </CSidebarNavItem>
    </CSidebarNav>-->
    <CargandoInfo :mostrarCarga="isLoadingModulos" :img="'/img/gesto/logo.png'" :heightImg="30" :textColor="'#fff'" :textSize="'0.7rem'" :mensajeInfo="'Obteniendo sus Módulos...'" />
                        
    <!--<div v-show="isLoadingModulos">
        <center>
            <img src="/img/logo2.png" size="custom-size" :height="30" class="imgr mt-3 mb-3" /> Obteniendo sus Módulos...
        </center>
    </div>-->
    <CRenderFunction v-show="!isLoadingModulos" flat :content-to-render="itemsNav" />
    
    
    <!--
    {{ colors }}
    <div style="margin-bottom: 70px;">
    
    <chrome-picker v-model="colors" />

    <material-picker v-model="colors" />
    <compact-picker v-model="colors" />
    <swatches-picker v-model="colors" />
    <slider-picker v-model="colors" />
    <sketch-picker v-model="colors" />
    <photoshop-picker v-model="colors" />
    <hr>
    </div>-->
    
    <CSidebarMinimizer
        :style="{'color': $store.state.colorSideBar.hex8}"
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
//import nav from './_nav'

  import cons from '@/const'
  import axios from "axios"
  import { freeSet } from '@coreui/icons'
import CargandoInfo from '@/views/utils/CargandoInfo'

  import { Material, Compact, Swatches, Slider, Sketch, Chrome, Photoshop } from 'vue-color'
import { CSidebar } from '@coreui/vue'
export default {
  name: 'TheSidebar',
  freeSet,
  components: {
    CargandoInfo,
    'material-picker': Material,
    'compact-picker': Compact,
    'swatches-picker': Swatches,
    'slider-picker': Slider,
    'sketch-picker': Sketch,
    'chrome-picker': Chrome,
    'photoshop-picker': Photoshop
  }, 
  //nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },
  data () {
    return {
      
        tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
        idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
        tipoUsuario: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,

        isLoadingModulos: false,

        itemsNav: [],

        colors: localStorage.getItem('color1')
    }
  },
  beforeMount() {
    this.getModulos()
    this.$store.state.sidebarMinimize = true;
  },
  methods: {
    getModulos: function(){
      
      this.isLoadingModulos = true
      
      var url = cons.port+"://" + cons.ipServer + "/get_modulos/";
        axios({
            method: "POST", 
            "url": url,
            "data": {
                "info": {
                    "idCliente": this.idCliente,
                    "tipoUsuario": this.tipoUsuario,
                }
            },
            "headers": {
                'Authorization': `${this.tokenLogin}`,
            }
        }).then(result => {
            

            this.itemsNav = [
                {
                    _name: 'CSidebarNav',
                    _children: result.data
                }
            ]
            this.isLoadingModulos = false
      }, error => {
            let mensaje = `Problema al obtener modulos, ${error}`
            this.isLoadingModulos = false
      })
      .finally(() => {
      });
      
        
    },
  }
}
</script>

<style scoped>
.c-sidebar-nav-item c-sidebar-nav-link:disabled{
    background-color: black;
}
.c-sidebar {
    color: red;
    background: #7E1146;
}
.c-sidebar .c-sidebar-brand {
    color: red;
    background: #fff
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: rgb(156,21,81, 0.2)
}
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle{
  color:rgba(255, 255, 255, 0.8);
}
@media (hover: hover), not all{
  .c-sidebar .c-sidebar-nav-link{
      background: #9C1551 !important;
  }
  .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle {
      
      background: #9C1551 !important;
  }
}

</style>